:root {
  --text-primary: #333;
  --text-secondary: #fff;
  --heading-primary: #111;
  --heading-secondary: #8b8b8b;
  --link-hover: #f8981c;
  --bg-color: #172337;
  --heading-color: #65686e;
  --list-color: #ffffff;
  --span-color: #2874f0;
  --svg-fill-color: #ffb800;
  --lines-color: #454d5e;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: "Varela Round", sans-serif;
  color: var(--text-primary);
  background-color: #fdfdfd; /*--dark-> #121212 */
}
h1,
h2,
h3 {
  font-family: "Ubuntu", sans-serif;
  color: var(--heading-primary) !important;
}
h4,
h5,
h6 {
  font-family: "Ubuntu", sans-serif;

  color: var(--heading-secondary) !important;
}
a {
  text-decoration: none;
  transition: all 0.2s linear;
}
a:hover {
  color: var(--link-hover) !important;
}

ul {
  list-style: none;
}

/* To cahnge the background color of the table head in mui data grid */
.css-f3jnds-MuiDataGrid-columnHeaders,
.css-1nitb7c {
  background-color: #391e65 !important;
  color: #efefef;
  text-align: center !important;
}

/* To align the column header in center in mui datagrid */
.css-1e2bxag-MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer,
.css-iclwpf .MuiDataGrid-columnHeaderTitleContainer {
  justify-content: center !important;
}

/* To make the text center in data Grid */
.css-1e2bxag-MuiDataGrid-root .MuiDataGrid-cell--textLeft,
.css-iclwpf .MuiDataGrid-cell--textLeft {
  justify-content: center !important;
}

/* Active NavLink Color */
.active {
  color: var(--link-hover);
}

.css-1pe4mpk-MuiButtonBase-root-MuiIconButton-root {
  color: #efefef !important;
}

/* To change the radio button color */
.MuiRadio-colorSecondary.Mui-checked {
  color: #f8981c !important;
}
.netBankingLogin {
  width: 100%;
  min-height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.netBankingLogin article {
  width: 90%;
  height: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
}
.netBankingLoginForm {
  width: 40%;
  height: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  box-shadow: 0 0 5px #666;
  padding: 20px;
  border: none;
  border-radius: 5px;
}
.netBankingLoginForm > button {
  background-color: #fbb454;
  color: #eee;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  padding: 10px 20px;
}
